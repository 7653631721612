import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import ReadMore from "../components/read-more"
import SiteHeader from "../components/site-header"
import SiteFooter from "../components/site-footer"
import styles from "./index.module.css"
import splash from "../../content/assets/splash.jpg"
import shareImage from "../../content/assets/default-content-image.jpg"

const Index = ({ data }) => {
  const siteDescription = data.site.siteMetadata.description
  const siteUrl = data.site.siteMetadata.siteUrl

  return (
    <div>
      <SEO title="Welcome" description={siteDescription} image={siteUrl + shareImage} url={siteUrl + "/"} />
      <SiteHeader />
      <div className={styles.splash} style={{ backgroundImage: "url(" + splash + ")" }}>
        <div className={styles.splashContent}>
          <h1>I live and work in Kentucky's beautiful horse country</h1>
          <p>My name is <strong>Jeremy Parnell</strong>. I'm a software developer, interactive designer, and entrepreneur. This is my personal website — a sandbox for testing code, ideas, and the occasional blog post.</p>
        </div>
      </div>
      <div className={styles.techBlock}>
        <div className={styles.content}>
          <h2>I design and build user experiences with</h2>
          <div className={styles.inner}>
            <div className={styles.item}>
              <h3>Microsoft .NET</h3>
              <p>Core / Standard / Framework</p>
            </div>

            <div className={styles.item}>
              <h3>Node.js</h3>
              <p>Angular / React</p>
            </div>

            <div className={styles.item}>
              <h3>Xamarin Mobile</h3>
              <p>iOS / Android</p>
            </div>

            <div className={styles.item}>
              <h3>Databases</h3>
              <p>Microsoft SQL Server / MySQL</p>
            </div>

            <div className={styles.item}>
              <h3>Languages</h3>
              <p>C# / PHP / TypeScript</p>
            </div>

            <div className={styles.item}>
              <h3>Design</h3>
              <p>Photoshop / Illustrator / Premiere</p>
            </div>
          </div>
        </div>
      </div>
      <ReadMore displayCount={6} isHome={true} />
      <SiteFooter />
    </div>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`
